<div class="background header"></div>
<div class="first-page container-fluid header" id="first-page">
    <p class="light text-center text-white fs-5 pe-5 ps-5 smaller-text">
        Психологическая игра для тех, кому важно сохранить
        <br class="hide-on-phone" />отношения с собой и своим партнером
    </p>
    <img class="image" src="/assets/lets_white_logo.svg" />
    <div class="row justify-content-center mb-5 mx-2">
        <div class="col-md-4 col-sm-12 mt-sm-5 text padding-sm fs-5 smaller-text">
            Вместе с профессиональными психологами<br class="hide-on-phone" />
            мы подготовили для вас интерактивные<br class="hide-on-phone" />
            колоды с вопросами на самые сложные<br class="hide-on-phone" />
            темы, волнующие любой партнерский союз.
        </div>
        <div class="col-md-4 col-sm-12 mt-sm-5 text padding-sm fs-5 smaller-text">
            Наша игра помогает бережно обсудить<br class="hide-on-phone" />
            болезненные темы, найти взаимопонимание<br class="hide-on-phone" />
            и выстроить эффективную коммуникацию со<br class="hide-on-phone" />
            своим партнером.
        </div>
        <div class="col-md-4 col-sm-12 mt-sm-5 text padding-sm fs-5 smaller-text">
            Все, что вам нужно, — отвечать<br class="hide-on-phone" />
            по очереди на вопросы, слушать<br class="hide-on-phone" />
            друг друга и искать точки
            <br class="hide-on-phone" />соприкосновения
        </div>
    </div>
    <img
        width="13px"
        src="/assets/arrow-first.svg"
        class="mt-5 mx-auto d-block arrow"
    />
</div>
