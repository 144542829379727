<form class="social-media-share" *ngIf="shareFormParams$ | async as params" [action]="params.url" validate>
  <input type="hidden" [name]="params.url_param" [value]="site">
  <p class="h3 text-white text-uppercase fw-bold text-center">Поделиться</p>
  <h5 class="mb-3 text-white text-uppercase h6 text-center mt-3">ВЫБЕРИТЕ ТЕКСТ И НАЖМИТЕ «ОТПРАВИТЬ»</h5>
  <div class="text-transform-none">
    <div class="form-check" *ngFor="let socialText of params.texts; index as i">
      <input class="form-check-input" type="radio" required [name]="params.text_param" id="socialText{{i}}"
        [value]="socialText">
      <label class="form-check-label" for="socialText{{i}}">{{socialText}}</label>
    </div>
  </div>
  <div style="padding-left: 30%">
    <button type="submit" class="btn btn-primary fs-2 rounded-3"
            style="min-width: 60%; border-radius: 10px; border: none">Отправить
    </button>
  </div>
</form>
