<div class="second-page container-fluid" id="second-page" *ngrxLet="data$ as data">
  <div class="first-part my-4">
    <div class="row align-items-center">
      <div class="col-sm-6 mb-3 mb-sm-4">
        <img width="450px" src="/assets/lets_black_logo.svg" class="float-lg-end float-md-end float-sm-start lets-image">
      </div>
      <div class="col-sm-5 mt-0">
        <p class="fs-3 ms-lg-5 ms-md-5 ms-sm-0 me-3 smaller-text text-communication">
          Можно использовать как <br/> первый шаг для выстраивания <br/> бережной коммуникации.
        </p>
      </div>
    </div>
    <div class="pt-5 d-none d-md-block">
      <img src="/assets/group-cards.svg" class="image">
    </div>
    <h3 class="col text-center fw-bold fs-3 text-talk">
      Вы можете поговорить о:
    </h3>
    <div class="my-lg-5 my-md-4 my-sm-4 mx-3 mx-md-5 deck-button-container text-uppercase">
      <a class="deck-button pink-enum text-center"
        *ngFor="let button of data.deck_buttons"
        [routerLink]="button.url">{{button.label}}</a>
    </div>
  </div>

  <app-marquee class="fw-bold mt-5 text-center fs-4">| {{data.deck_count}} колод и 500+ вопросов на самые сложные темы</app-marquee>
</div>
